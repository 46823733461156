




































































































































import { defineComponent, reactive, toRefs, computed, ref } from '@vue/composition-api';
import axios from 'axios';

export default defineComponent({
  name: 'TeamOwner',
  props: {
    programDoc: {
      required: false,
      type: Object,
      default: () => {}
    },
    teamDoc: {
      required: true,
      type: Object
    },
    viewer: {
      required: true,
      type: Object
    },
    userType: {
      required: true,
      type: String
    }
  },
  setup(props, ctx) {
    const state = reactive({
      renameTeamDialog: false,
      leaveTeamDialog: false,
      changePasswordDialog: false,
      newTeamName: '',
      selectedMember: null as null | Record<string, any>,
      password: props.teamDoc.password,
      showPassword: false
    });
    const isLoading = ref(false);

    const teamMembers = computed(() => {
      return props.teamDoc?.members?.filter(member => {
        return !member._id.equals(props.teamDoc.owner);
      });
    });

    const teamPassword = computed({
      get: () => state.password,
      set: newVal => {
        state.password = newVal;
      }
    });

    async function teamOperation(type) {
      try {
        isLoading.value = true;
        const data = {
          type,
          team_id: props.teamDoc._id,
          public_id: props?.programDoc?.data?._id?.toString()
        };
        if (type === 'change_password') data.password = state.password;
        else if (type === 'change_name') data.name = state.newTeamName;
        else data.owner = props.teamDoc.owner;

        const teamRes = await axios.patch(`${process.env.VUE_APP_STUDENT_SERVICE}/team`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });

        if (teamRes.data.data) {
          isLoading.value = false;
          ctx.emit('update', teamRes.data.data);
          state.renameTeamDialog = false;
          state.leaveTeamDialog = false;
          state.changePasswordDialog = false;
        }
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    }

    return {
      ...toRefs(state),
      teamMembers,
      isLoading,
      teamOperation,
      teamPassword
    };
  }
});
